<template>
  <div>
  <CCard>
   
  <CCardBody>
    <!-- <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewLocation">Add New Crane</button>
    </div> -->

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
      >
        <template #status-filter>
          <select
            class="form-control form-control-sm"
            @input="
              $refs.vuetable.columnFilterEvent(
                'status',
                $event.target.value,
                'input'
              )
            "
          >
            <option value selected="selected">Any</option>
            <option value="1">Pending</option>
            <option value="2">Completed</option>
          </select>
        </template>
         <template #statusRange-filter>
          <select
            class="form-control form-control-sm"
            @input="
              $refs.vuetable.columnFilterEvent(
                'statusRange',
                $event.target.value,
                'input'
              )
            "
          >
            <option value selected="selected">Any</option>
            <option value="1">Urgent</option>
            <option value="2">Normal</option>
             <option value="3">Super Urgent</option>
          </select>
        </template>
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>
<template #status="{item}">
        <td class='font-weight-bold'
          v-if="item.status==1"
        >Pending</td>
        <td class='font-weight-bold' v-else>Completed</td>
      </template>
      <template #statusRange="{item}">
        <td class='font-weight-bold'
          v-show="item.statusRange==1">Urgent</td>
        <td class='font-weight-bold' v-show="item.statusRange==2">Normal</td>
        <td class='font-weight-bold' v-show="item.statusRange==3">Super Urgent</td>
      </template>
      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
      <template #firstName="{item}">
        <td class='font-weight-bold'
             v-if="item.firstName && item.lastName"
        >{{ item.firstName }} {{item.lastName }}</td>
        <td v-else>--</td>
      </template>
      <template #lastName="{item}">
        <td class='font-weight-bold'
          v-if="item.lastName"
        >{{ item.lastName  }}</td>
        <td v-else>--</td>
      </template>
       <template #targetDate="{item}">
        <td class='font-weight-bold'
          v-if="item.targetDate"
        >{{ item.targetDate |dateParse('YYYY.MM.DD')| dateFormat("MMM D, YYYY ") }}</td>
        <td v-else>--</td>
      </template>
      <template #commencementDate="{item}">
        <td class='font-weight-bold'
          v-if="item.commencementDate"
        >{{ item.commencementDate |dateParse('YYYY.MM.DD')| dateFormat("MMM D, YYYY ") }}</td>
        <td v-else>--</td>
      </template>
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                   <CDropdownItem :to="{name: 'Job Tasks', params: { jobId: item. jobId, mode: 'view'}}">Job Tasks</CDropdownItem> 
                   <CDropdownItem :to="{name: 'Inspections', params: { jobId: item.jobId,mode: 'view'}}">Inspection</CDropdownItem> 
                <!-- <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteLocation(item.craneId)"
                    ></ConfirmationModal> -->
                </CDropdown> 

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>

 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
  { key: "firstName",label: "Submitted By", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  //  { key: "lastName",label: "Last Name", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "location",label: "Location", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "targetDate",label: "Target Date", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "status",label: "Status", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "contractNo",label: "Contract No", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "contractor",label: "Contractor", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "commencementDate",label: "Commencement Date", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "statusRange",label: "Status Range", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "terminalName",label: "Terminal Name", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  
  { key: "createdTime",label: "Created Date & Time", _classes: 'font-weight-bold', _style: "min-width:200px" },
  
  { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "Civil Maintenances",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getLocations() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/civil/jobs")
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
   
  
     deleteLocation(craneId) {
      console.log("delete called with",  craneId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/crane/" +
         craneId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getLocations();
             this.$nextTick(() => {
             this.$refs.vuetable.refresh();
           });
           window.location.reload();
        });
    },
   
  },
  mounted() {
    this.getLocations();
  },
};
</script>